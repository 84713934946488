import Cookie from 'js-cookie'
const CryptoJS = require("crypto-js");

export const session = () =>{
    let out = false
    var getCookie = Cookie.get(process.env.REACT_APP_AUTH_COOKIE)
    // getCookie = CryptoJS.AES.encrypt('my message', process.env.REACT_APP_SECRET_CODE).toString();
    if (getCookie) {
        var bytes  = CryptoJS.AES.decrypt(getCookie, process.env.REACT_APP_SECRET_CODE);
        var originalText = bytes.toString(CryptoJS.enc.Utf8);
        if(originalText){
            try {
                out = JSON.parse(originalText)      
            } catch (error) {
                out = false
            }
           
        }
    }
    return out
}

export function setSession(data) {
    const cookieValue = JSON.stringify(data)
    console.log(cookieValue)
    const encCookie = CryptoJS.AES.encrypt(cookieValue, process.env.REACT_APP_SECRET_CODE).toString();
    Cookie.set(process.env.REACT_APP_AUTH_COOKIE, encCookie)
}

export function clearSession(data) {
    Cookie.remove(process.env.REACT_APP_AUTH_COOKIE) 
}



