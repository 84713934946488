import React from 'react'
import {
    BrowserRouter as Router, Routes, Route, Outlet
} from "react-router-dom";

import Home from "../../pages/Home";
import About from "../../pages/About";
import ErrorPage from "../../pages/ErrorPage";
import Navigation from '../Navigation';
import Blogs from '../../pages/Blogs';
import Blog from '../../pages/Blog';
import Live from '../../pages/Live';
import Community from '../../pages/Community';
import Store from '../../pages/Store';
import Footer from '../Footer';
import RunningText from '../../pages/RunningText';
import MainLayout from '../../layout/MainLayout';
import FreeLayout from '../../layout/FreeLayout';
import Profile from '../../pages/Profile';
import Schedule from '../../pages/Schedule'
import Privacy from '../../pages/Privacy'
import Chart from '../../pages/Chart'
import Promo from '../../pages/Promo'
import Showoff from '../../pages/Showoff'
import Marketplace from '../../pages/Marketplace'





const RootRouter = () => {
    return (
        <>
            <Router>
                <Routes>

                    <Route path='/' element={<MainLayout />}>
                        <Route index element={<Home/>} />
                        <Route path='/live' element={<Live />} />
                        <Route path='/community' element={<Community />} />
                        <Route path='/blogs' element={<Blogs />} />
                        <Route path='/blog/:slug' element={<Blog />} />
                        <Route path='/about' element={<About />} />
                        <Route path='/store' element={<Store />} />
                        <Route path='/profile' element={<Profile />} />
                        <Route path='/schedule' element={<Schedule />} />
                        <Route path='/privacy' element={<Privacy />} />
                        <Route path='/chart' element={<Chart />} />
                        <Route path='/promo' element={<Promo />} />
                        <Route path='/showoff' element={<Showoff />} />
                        <Route path='/marketplace' element={<Marketplace />} />


                    </Route>

                    <Route path='/free' element={<FreeLayout/>}>
                        <Route path='running-text' element={<RunningText/>}/>

                    </Route>
                    
                    <Route path='*' element={<ErrorPage />} />
                </Routes>
                
            </Router>

            
        </>

    )

}


export default RootRouter