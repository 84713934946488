"use strict";

import { useState, useEffect } from "react";
import Banner from '../../assets/banner_showoff.png'
import QR from '../../assets/qrcode.png'
import { Api } from "../../utils/apiServer";
import { API } from "../../utils/api";
import axios from 'axios'
import { session, clearSession, setSession } from '../../utils/AuthSession'
import SocialButton from "../../components/SocialButton";
import Dialog from '../../components/Dialog'

const Marketplace = () => {
    const [authSession, setAuthSession] = useState(session())
    const [url,setUrl] = useState(process.env.REACT_APP_LOYALTY_PAGE)
    const [cid,setCID] = useState(process.env.REACT_APP_LOYALTY_CID)
    const [sid,setSID] = useState(process.env.REACT_APP_LOYALTY_SID)
    const [data, setData] = useState({
        name: session()?.user?.firstname + ' ' + session()?.user?.lastname,
        phone: session()?.user?.phone,
        phone: session()?.user?.email,
        instagram: "",
        title: "",
        singer: "",
        songwriter: "",
        link: ""
    })
    const [reload, setReload] = useState(false)
    const [dialogTitle, setDialogTitle] = useState()
    const [dialogMessage, setDialogMessage] = useState()
    useEffect(() => {
        postIP();
    }, []);

    useEffect(() => {
    }, [data])
    const handleOnSuccess = () => {
        setAuthSession(session())

        window.location.reload(true);
    }
    // console.log(session())
    const postIP = async () => {
        const ipaddress = await axios.get(process.env.REACT_APP_API_GETIP)
        const params = {
            url: API.IP.url,
            method: API.IP.type,
            access: 'no auth',
            reqBody: {
                page: '/showoff',
                ip: ipaddress.data.ip,
                platform: "Web"
            }
        }
        const response = await Api(params)
    }

    // console.log('session',session().user.email)
    return (
        <div className='p-5 text-white md:pl-20 md:pr-20 xl:pl-40 xl:pr-40 '>
            {authSession ? (
                <div className="h-screen flex bg-white mt-5 mb-5 rounded-2xl text-black justify-center">
                    <iframe className="w-screen" src={`${url}${cid}/${sid}/${session()?.user?.email}`} frameborder="0"></iframe>
                </div>
            ) : (
                <div>
                    <div className=''><img src={Banner} className="w-" alt="" /></div>
                    <div className='flex pt-5 pb-5 gap-4 bg-white rounded-2xl mt-5 place-content-center justify-center items-center'>

                        <div>
                            <SocialButton onSuccess={handleOnSuccess} />
                        </div>
                        <div className="text-black text-xl"><b>Login masuk ke marketplace</b></div>
                    </div>
                </div>

            )}

            
        </div>


    )
}

export default Marketplace