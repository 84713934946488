import { useState, useEffect } from "react";
import { gapi } from 'gapi-script';
import { GoogleLogin, GoogleLogout } from 'react-google-login'
import axios from 'axios';
import { setSession } from "../../utils/AuthSession";

const SocialLogin = (props) => {

    useEffect(() => {
        function start() {
            gapi.client.init({
                clientId: process.env.REACT_APP_MIC_STREAMING_GOOGLE_ID,
                scope: 'email',
            });
        }
        gapi.load('client:auth2', start);
    }, []);

    const handleOnSuccess = async (response) => {
        // login to core
        
        let res
        try {
            res = await axios.post(process.env.REACT_APP_CORE_MIC_STREAMING+'auth/login/google',{
                id_token : response.tokenId
            },{
                headers: {
                    'Content-Type': 'application/json'
                }
            })

            if(res.data.status){
                console.log('response',res.data.data)
                setSession(res.data.data)
            }
                
        } catch (error) {
            alert('Fail to connect')
        }
        
        // update session
        props.onSuccess()

    }

    const handleOnFailure = (response) => {
        console.log("Sadjashdklj")
    }

    return (
        <GoogleLogin
            clientId={process.env.REACT_APP_MIC_STREAMING_GOOGLE_ID}
            buttonText="Login"
            onSuccess={handleOnSuccess}
            onFailure={handleOnFailure}
            cookiePolicy={'single_host_origin'}
            isSignIn={true}
        />
    )
}

export default SocialLogin;
